import React from 'react'

import { LinearGradientComponent } from '../components'
import { GradientButtonProps } from '../components/LinearGradient'
import { View } from 'react-native'

export function LinearGradientWrapper({
    children,
    isGradient,
    colors,
    style = {},
    ...gradientProps
}: GradientButtonProps & { isGradient: boolean; colors?: string[] }) {
    return isGradient ? (
        <LinearGradientComponent
            colors={colors}
            style={style}
            {...gradientProps}
        >
            {children}
        </LinearGradientComponent>
    ) : (
        <View style={style}>{children}</View>
    )
}
