import React, { useEffect, useRef } from 'react'
import {
    Animated,
    Easing,
    Pressable,
    StyleProp,
    StyleSheet,
    View,
    ViewStyle,
} from 'react-native'

export interface DotPaginationProps {
    count: number
    onSelected: (index: number) => void
    icon?: React.ReactNode
    containerStyle?: StyleProp<ViewStyle>
    dotStyle?: ViewStyle
    dotSelectedStyle?: ViewStyle
    selectedIndex?: number
    testID?: string
}

const ANIMATED_HEIGHT = 16

const DotPagination = (props: DotPaginationProps) => {
    const {
        count,
        dotStyle,
        dotSelectedStyle,
        containerStyle,
        selectedIndex,
        onSelected,
        testID,
    } = props

    const propHeight = parseInt(dotStyle?.height as string)
    const dotItemHeight = !isNaN(propHeight) ? propHeight : styles.circle.height

    const propSelectedHeight = parseInt(dotSelectedStyle?.height as string)
    const selectedHeight = !isNaN(propSelectedHeight)
        ? propSelectedHeight
        : ANIMATED_HEIGHT

    // Calculate neighbor dot height
    const getHeight = (dotIndex, selectedIndex, baseHeigth) => {
        if (dotIndex + 1 == selectedIndex || dotIndex - 1 == selectedIndex) {
            return Math.round(baseHeigth * 1.3)
        } else return baseHeigth
    }

    return (
        <View
            style={[
                styles.container,
                { height: selectedHeight },
                containerStyle,
            ]}
            testID={`${testID}-dot-container`}
        >
            {Array(count)
                .fill(0)
                .map((_, dotIndex) => {
                    const height = getHeight(
                        dotIndex,
                        selectedIndex,
                        dotItemHeight,
                    )

                    return (
                        <Pressable
                            testID={`${testID}-dot-pressable-${dotIndex}`}
                            key={`dot-${dotIndex}`}
                            onPress={() => onSelected?.(dotIndex)}
                        >
                            <DotItem
                                index={dotIndex}
                                dotStyle={dotStyle}
                                selected={dotIndex == selectedIndex}
                                height={height}
                                selectedHeight={selectedHeight}
                                testID={testID}
                            />
                        </Pressable>
                    )
                })}
        </View>
    )
}

interface DotItem {
    selected: boolean
    index: number
    height: number
    dotStyle?: ViewStyle
    selectedHeight: number
    dotSelectedStyle?: ViewStyle
    testID?: string
}

const DotItem = (props: DotItem) => {
    const {
        selected,
        index,
        dotStyle,
        height,
        dotSelectedStyle,
        selectedHeight,
        testID,
    } = props
    const selectedAnimationHeight = useRef(new Animated.Value(height)).current
    const selectedAnimationRadius = useRef(
        new Animated.Value(height / 2),
    ).current

    useEffect(() => {
        if (selected) {
            Animated.timing(selectedAnimationHeight, {
                toValue: selectedHeight,
                duration: 500,
                easing: Easing.linear,
                useNativeDriver: false,
            }).start()
            Animated.timing(selectedAnimationRadius, {
                toValue: selectedHeight / 2,
                duration: 500,
                easing: Easing.linear,
                useNativeDriver: false,
            }).start()

            return
        }

        Animated.timing(selectedAnimationHeight, {
            toValue: height,
            duration: 500,
            easing: Easing.linear,
            useNativeDriver: false,
        }).start()
        Animated.timing(selectedAnimationRadius, {
            toValue: height / 2,
            duration: 500,
            easing: Easing.linear,
            useNativeDriver: false,
        }).start()
    }, [selected, index, height])

    return (
        <Animated.View
            testID={`${testID}-dot-pagination-${index}`}
            style={[
                styles.circle,
                dotStyle,
                selected && dotSelectedStyle,
                {
                    height: selectedAnimationHeight,
                    width: selectedAnimationHeight,
                    borderRadius: selectedAnimationRadius,
                },
            ]}
        />
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: 5,
        backgroundColor: 'black',
        margin: 2,
    },
})

export default DotPagination
